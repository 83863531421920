import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import Layout from "../layouts/layout-scroll"

class Item extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true
    }
  }
  render() {
    return (
      <li className={this.state.collapsed ? "timeline-item" : "timeline-item expanded"}
      onClick={() => this.setState(state => ({ collapsed: !state.collapsed }))}  >
          <div className="timeline-info" >
              <span>{this.props.info}</span>
          </div>
          <div className="timeline-marker"/>
          <div className="timeline-content">
              { this.props.title && <h3 className="timeline-title">{this.props.title}</h3>}
              { this.props.subtitle && <h4 className="timeline-title">{this.props.subtitle}</h4>}
              <p
                className={this.state.collapsed ? null : "expanded"}
                dangerouslySetInnerHTML={{__html: this.props.text}}
              />
              { this.props.text2 && (
                <p
                  className={this.state.collapsed ? null : "expanded"}
                  dangerouslySetInnerHTML={{__html: this.props.text2}}
                />
              )}
          </div>
      </li>
    )}
}

class ItemSection extends React.Component {
  render() {
    return (
      <li className="timeline-item period">
          <div className="timeline-info"/>
          <div className="timeline-marker"/>
          <div className="timeline-content">
              <h2 className="timeline-title">{this.props.title}</h2>
          </div>
      </li>
    )}
}

export default ({data}) =>
  <Layout>
    <Helmet htmlAttributes={{ class:'pro resume' }} />
    <SEO title="Resume"
      description={data.site.siteMetadata.descriptions.resume} />
    <>
      <h1>Resume</h1>
      <div style={{ marginTop: '3rem', }}>
          <div className="container-fluid">
              <div className="row">
                  <div className="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2">
                      <ul className="timeline timeline-centered">

                          <ItemSection title="Jobs" />
                          <Item info="Since June 2014"
                                title="Prisma Media"
                                subtitle="Lead Android Dev"
                                text="Prisma Media is the French leader of print & digital press."
                                text2="Here, I'm in charge of the development of various apps & working on improving the organization of our Android team which is mostly remote."
                                />

                          <Item info="Since May 2011"
                                title="Météo Villes"
                                subtitle="Freelance Dev (Android / iOS / React Native)"
                                text="Working with a well known meteorologist on his applications, initially on Android only, then also on iOS."
                                text2="<a href='https://www.meteo-villes.com/' target='_blank'>Website</a>"
                                />

                          <Item info="February 2009 - April 2014"
                                title="Cardiweb"
                                subtitle="Web & mobile developer"
                                text="Working as a contractor for various clients, such as high trafic websites like <a href='http://www.francebillet.com'>France Billet</a>, <a href='http://www.fnacspectacles.com'>Fnac Spectacles</a> or large mobile apps flows for retail with <a href='https://www.urw.com/fr-FR'>Unibail-Rodamco</a>"
                                />

                          <ItemSection title="Side Projects" />
                          <Item info="201x"
                                title="EndOfLine"
                                text="This website is a way for me to try various things, it's always under development"
                                />

                          <Item info="Since 2016"
                                title="Chain App"
                                text="Stay updated about the latest mountain bike news!"
                                text2="<a href='https://chainapp.news/' target='_blank'>Website</a>"
                                />

                          <Item info="2015"
                                title="Colorly"
                                text="A basic game about colors"
                                text2="<a href='http://colorly-app.com/' target='_blank'>Website</a>"
                                />

                          <ItemSection title="Studies" />
                          <Item info="2009"
                                title="MSc Networking, Services & Mobility"
                                subtitle="Henri Poincaré University - Nancy (FR)"
                                text="Masters degree with a large background in software development & major in network systems to keep your mind & sight open."
                                />

                          <Item info="2008"
                                title="Bachelor in Computer Sciences"
                                subtitle="Henri Poincaré University - Nancy (FR)"
                                text="Major in software development"
                                />

                          <ItemSection title="Interests" />
                          <Item info="Mountain Bike"
                                text="Riding since I'm a little kid - currently in enduro" />
                          <Item info="Photography"
                                text="My first travel gave me the passion of beautiful landscapes !" />
                          <Item info="Motorbike"
                                text="For the feeling of freedom you have while riding on two wheels" />
                          <Item info="Travels"
                                text="Discovering incredible sights & meeting strangers" />
                          <Item info="Music"
                                text="Played the guitar for a while & still vibrating when hearing strings vibrating" />
                      </ul>
                  </div>
              </div>
          </div>
      </div>
    </>
  </Layout>

export const query = graphql`
  query ResumeQuery {
    site {
      siteMetadata {
        title
        descriptions {
          resume
        }
      }
    }
  }
`
